import React from 'react';
import Helmet from 'react-helmet';

import { Layout } from 'containers';
import {
  PageNav,
  AltPanels,
} from 'layouts';
import {
  Banner,
  Breadcrumbs,
} from 'components';
import { PAGES, BASE_URL } from 'config';

const BREADCRUMBS = [
  {
    name: 'About Us',
    absolute_slug: PAGES.ABOUT_BRAND.PATH,
  },
  {
    name: PAGES.ABOUT_VALUES.TITLE,
    absolute_slug: PAGES.ABOUT_VALUES.PATH,
  },
];

const AboutValuesPage = () => (
  <Layout page="about/our-values">
    <Helmet
      title="The Core Values That We Honour | Haka Tours, NZ"
      meta={[
        {
          name: 'description', content: `Haka Tourism Group is always looking for ways to honor and respect Maori cultural traditions, customs and values.
          Read more on the ways we incorporate three guiding principles into all we do.`,
        },
        { name: 'og:title', content: 'The Core Values That We Honour | Haka Tours, NZ' },
        {
          name: 'og:description', content: `Haka Tourism Group is always looking for ways to honor and respect Maori cultural traditions, customs and values.
          Read more on the ways we incorporate three guiding principles into all we do.`,
        },
      ]}
      link={[{ rel: 'canonical', href: `${BASE_URL}/about-us/our-values/` }]}
    />

    <Banner backgroundImage="https://static-hakatours.imgix.net/about-page-banner.jpg?fit=crop&auto=format">
      <h2 className="c-heading c-heading--h0">Our Values</h2>
    </Banner>
    <PageNav pages={[PAGES.ABOUT_TRIPS, PAGES.ABOUT_STORY, PAGES.ABOUT_BRAND, PAGES.ABOUT_PEOPLE, PAGES.ABOUT_AWARDS, PAGES.ABOUT_FAQS, PAGES.ABOUT_REVIEWS]} />
    <section className="l-section">
      <div className="l-container">
        <Breadcrumbs list={BREADCRUMBS} />
      </div>
      <div className="l-container l-container--small u-text--center">
        <h1 className="c-heading c-heading--h2">Ngā Mātāpono: The Values we Uphold</h1>
        <p>
          Māori values are instruments by which Māori people view, interpret and make sense of the world. The universal values and beliefs are centered around land, water,
          and air as the essential ingredients of life that are to be respected, cherished, and sustained. Haka Tourism Group is always looking for ways to honor and respect cultural traditions,
          customs and values, and we choose our Tour Managers and staff carefully, recruiting for attitude over aptitude to ensure our staff’s personal values are aligned with ours.
          The three Māori values that are our guiding principles are explained below.
        </p>
      </div>
    </section>
    <AltPanels items={[
      {
        title: 'Value 1: Whanaungatanga [pronounced far-no-nga-tung-a]',
        content: `<p>Whanaungatanga is about relationships and feeling a sense of belonging and we believe that exploring New Zealand in small group makes for a far richer and personalised experience.
        It truly allows a group a strangers to build deep friendships as well as enabling a more intimate connection with the local people and places that we explore.
        Find out more about how we are honouring the value of Whanaungatanga <a href="https://s3-ap-southeast-2.amazonaws.com/files.hakatours.com/Mission+Values+and+Goals+Haka+Tourism+Group.pdf" target="_blank">here</a>.
        </p>`,
        image: {
          width: 652,
          height: 418,
          title: 'Example image title',
          url: 'https://static-hakatours.imgix.net/our-values-01b.jpg?auto=format',
          align: '',
        },
      },
      {
        title: 'Value 2: Kaitiakitanga [pronounced ky-tee-ah-kee-tunga-a]',
        content: `<p>Kaitiakitanga is about guardianship and the connection between people and the natural world. It states that it is our job as humans to be guardians of the natural environment,
        to look after resources and ensure their survival for future generations. Being a New Zealand owned company, we feel a great responsibility to honor,
        protect and preserve it in the best way we can.
        Find out more about how we are honouring the value of Kaitiakitanga <a href="https://s3-ap-southeast-2.amazonaws.com/files.hakatours.com/Mission+Values+and+Goals+Haka+Tourism+Group.pdf" target="_blank">here</a>.</p>`,
        image: {
          width: 652,
          height: 418,
          title: 'Example image title',
          url: 'https://static-hakatours.imgix.net/our-values-02b.jpg?auto=format',
          align: '',
        },
      },
      {
        title: 'Value 3: Manaakitanga [pronounced ma-naa-kee-tung-a]',
        content: `<p>Manaakitanga is about hospitality and the act of caring for a person's mana (dignity). We do this by showing kindness,
        generosity and support to others and by welcoming all our guests with open arms. We strive to deliver world-class customer service, and to offer products and services that allow for flexibility,
        personalisation and inclusion.
        Find out more about how we are honouring the value of Manaakitanga <a href="https://s3-ap-southeast-2.amazonaws.com/files.hakatours.com/Mission+Values+and+Goals+Haka+Tourism+Group.pdf" target="_blank">here</a>.</p>`,
        image: {
          width: 652,
          height: 418,
          title: 'Example image title',
          url: 'https://static-hakatours.imgix.net/our-values-03b.jpg?auto=format',
          align: '',
        },
      },
    ]}
    />

    <section className="l-section">
      <div className="l-container l-container--small u-text--center">
        <p>&nbsp;</p>
      </div>
    </section>

    {/* <BlogList */}
    {/* title="The Latest Haka News" */}
    {/* list={[1, 2]} */}
    {/* /> */}

  </Layout>
);

export default AboutValuesPage;
